html {
  width: 100%;
  height: 100%;
}
body {
  width: 100%;
  height: 100%;
}
#root {
  height: 100%;
}

/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

a:focus,
button:focus {
  outline: none;
}

.spaced-vertical-items > * {
  margin-bottom: 12px;
  display: block;
}
.spaced-horizontal-items > * {
  margin-right: 8px;
}
